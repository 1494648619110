import CryptoJS from 'crypto-js';

export const createJWT = ({
  header = { alg: 'HS256', typ: 'JWT' },
  payload = {},
  secret = '',
}) => {
  const base64UrlEncode = (obj) => {
    return btoa(JSON.stringify(obj))
      .replace(/=+$/, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  };

  const encodedHeader = base64UrlEncode(header);
  const encodedPayload = base64UrlEncode(payload);
  const token = `${encodedHeader}.${encodedPayload}`;
  const signature = CryptoJS.HmacSHA256(token, secret);

  const encodedSignature = signature
    .toString(CryptoJS.enc.Base64)
    .replace(/=+$/, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  const jwt = `${token}.${encodedSignature}`;

  return jwt;
};

export const decodeJWT = (jwt) => {
  const [header, payload] = jwt.split('.');

  const base64UrlDecode = (str) => {
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    const padding = '='.repeat((4 - (base64.length % 4)) % 4);
    base64 += padding;
    return JSON.parse(atob(base64));
  };

  const decodedHeader = base64UrlDecode(header);
  const decodedPayload = base64UrlDecode(payload);

  return { decodedHeader, decodedPayload };
};
