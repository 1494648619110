import scopes from 'constants/scopes';
import plans from 'constants/plans';
import { contextRoutes } from 'constants/contextRoutes';

export const getContextRoutes = ({ planId = undefined }) => {
  return contextRoutes[planId];
};

export const getAccessRoutes = ({
  planId = undefined,
  scopeId = undefined,
  skinId = undefined,
}) => {
  if (!planId && !scopeId && !skinId) return [];

  const internalRoutes = [
    '/gerenciar-empresas',
    '/consultar-acessos',
    '/gerenciar-implantacao',
    '/conciliacao-retroativa',
    '/radar',
    '/regras-conciliacao',
    '/regras-ofx',
  ];

  const { accessRoutes = [] } = getContextRoutes({ planId });

  if (scopeId === scopes.CONCIL && planId === plans.FREE) {
    return [...accessRoutes, '/registro-de-implantacao'];
  }

  if (scopeId !== scopes.CONCIL) {
    return accessRoutes?.filter((route) => !internalRoutes.includes(route));
  }

  return accessRoutes;
};

export const getDefaultRoute = ({ planId = undefined }) => {
  if (!planId) return '';
  const { defaultRoute = '' } = getContextRoutes({ planId });
  return defaultRoute;
};
