import React from 'react';
import { Form, Grid, Input } from 'antd';
import InputMaskPhone from 'commons/InputMaskPhone/InputMaskPhone';
import InputMaskRg from 'commons/InputMaskRg/InputMaskRg';
import { InputMaskCpf } from 'commons/InputMaskCpf';
import { cpf } from 'helpers/cpf';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { validateDDD } from 'helpers/ddd/validateDDD';
import {
  StBanner,
  StBannerContainer,
  StButton,
  StContainer,
  StDivider,
  StFormContainer,
  StFormTitle,
  StLink,
  StLogo,
  StPage,
  StText,
} from './styled';

const baseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const logo = `${baseURL}/login/logo.png`;
const graphMan = `${baseURL}/img/graphMan.png`;

export const RegisterStep1 = ({
  initialValues,
  onFinish,
  loading,
  dataTestPrefix,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [form] = Form.useForm();

  const onClickFinish = (values) => {
    onFinish(values);
  };

  return (
    <StPage $breakpoint={breakpoint}>
      <StContainer $breakpoint={breakpoint}>
        <StFormContainer>
          <StLogo src={logo} alt="Logo Concil Card" />
          <StFormTitle>Criar conta</StFormTitle>
          <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            requiredMark={false}
            onFinish={!loading && onClickFinish}
            style={{ maxWidth: '400px', margin: '0 auto' }}
          >
            <Form.Item
              name="name"
              label="Nome Completo"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(new Error('Campo obrigatório'));
                    }

                    if (
                      value.length < 2 ||
                      value.trim() === '' ||
                      value.trim().length < 2 ||
                      !/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/.test(value)
                    ) {
                      return Promise.reject(new Error('Nome inválido'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder="Insira o nome"
                disabled={loading}
                {...dataTestIdHandler(dataTestPrefix, 'name')}
              />
            </Form.Item>

            <Form.Item
              name="rg"
              label="RG"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(new Error('Campo obrigatório'));
                    }

                    if (
                      value.length < 8 ||
                      value.trim() === '' ||
                      value.trim().length < 8
                    ) {
                      return Promise.reject(new Error('RG inválido'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputMaskRg
                placeholder="Insira o RG"
                disabled={loading}
                {...dataTestIdHandler(dataTestPrefix, 'rg')}
              />
            </Form.Item>

            <Form.Item
              name="cpf"
              label="CPF"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  validator: (_, value) => {
                    if (!value || cpf.isValid(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('CPF inválido'));
                  },
                },
              ]}
            >
              <InputMaskCpf
                placeholder="Insira o CPF"
                disabled={loading}
                {...dataTestIdHandler(dataTestPrefix, 'cpf')}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'email', message: 'E-mail inválido' },
              ]}
            >
              <Input
                placeholder="Insira o e-mail"
                disabled={loading}
                {...dataTestIdHandler(dataTestPrefix, 'email')}
              />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Telefone"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    const numericValue = value.replace(/\D/g, '');

                    if (
                      numericValue.length >= 10 &&
                      numericValue.length <= 11
                    ) {
                      const ddd = numericValue.slice(0, 2);

                      if (validateDDD(ddd)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('DDD inválido'));
                    }
                    return Promise.reject(new Error('Telefone inválido'));
                  },
                },
              ]}
            >
              <InputMaskPhone
                placeholder="Insira o telefone"
                disabled={loading}
                {...dataTestIdHandler(dataTestPrefix, 'phone')}
              />
            </Form.Item>

            <StDivider plain>Etapa 1 de 2</StDivider>

            <Form.Item>
              <StButton
                type="primary"
                htmlType="submit"
                loading={loading}
                {...dataTestIdHandler(dataTestPrefix, 'next-step-submit')}
              >
                Próximo
              </StButton>
            </Form.Item>
          </Form>
          <StText>
            Já possui conta?{' '}
            <StLink to="/" {...dataTestIdHandler(dataTestPrefix, 'login-link')}>
              Fazer login
            </StLink>
          </StText>
        </StFormContainer>
        <StBannerContainer>
          <StBanner>
            Crie sua conta e comece a gestão do seu negócio com o nosso plano
            gratuito
          </StBanner>
          <img
            src={graphMan}
            alt="Pessoa apontando para um gráfico"
            width={342}
          />
        </StBannerContainer>
      </StContainer>
    </StPage>
  );
};
