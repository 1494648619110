import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useNavigate } from 'react-router-dom';
import { plansLogo } from 'constants/plansLogo';
import {
  StBack,
  StBackDescription,
  StBorder,
  StButton,
  StCloseOutlined,
  StCover,
  StHeader,
  StInfoCircleOutlined,
  StTitle,
} from './styled';

const Cover = ({ children, onFlip }) => {
  return (
    <StCover onClick={onFlip}>
      {children}
      <StBorder>
        <StInfoCircleOutlined />
      </StBorder>
    </StCover>
  );
};

const Back = ({ title, description, planAvailable, onFlip }) => {
  const navigate = useNavigate();
  const planLogo = plansLogo[planAvailable];
  const onPlansCompareClick = () => navigate('/planos');

  return (
    <StBack>
      <StHeader>
        <StTitle>{title}</StTitle>
        {planLogo && <img src={planLogo} alt="Logotipo do plano disponível" />}
      </StHeader>
      <StBackDescription>{description}</StBackDescription>

      <StButton type="primary" onClick={onPlansCompareClick}>
        Consultar planos
      </StButton>

      <StBorder onClick={onFlip}>
        <StCloseOutlined />
      </StBorder>
    </StBack>
  );
};

const BlockedFlipCard = ({
  title = '',
  backDescription = '',
  planAvailable,
  children,
  ...others
}) => {
  const [flipped, setFlipped] = useState(false);

  const onFlip = () => setFlipped(!flipped);

  return (
    <div {...others}>
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        <Cover onFlip={onFlip}>{children}</Cover>
        <Back
          title={title}
          description={backDescription}
          planAvailable={planAvailable}
          onFlip={onFlip}
        />
      </ReactCardFlip>
    </div>
  );
};

export default BlockedFlipCard;
