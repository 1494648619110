import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { currentPlanCardDetails } from 'constants/currentPlanCardDetails';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import {
  StCongratulations,
  StCongratulationsContainer,
  StContainer,
  StCurrentPlan,
  StGreetings,
  StPlan,
  StPlanContainer,
  StPlanLabel,
  StPlanName,
  StPlansCompare,
  StTrophyFilled,
} from './styled';

const CurrentPlanCard = ({ username, planId, skinId, ...others }) => {
  const navigate = useNavigate();
  const dataTestPrefix = 'current-plan-card';

  if (!planId && !skinId) return null;

  const {
    name,
    usernameColor,
    textColor,
    color,
    backgroundColor,
    hasPlansCompareLink,
  } = currentPlanCardDetails[planId] || {};

  if (!name) return null;

  const onPlansCompareClick = () => navigate('/planos');

  return (
    <StContainer
      color={textColor}
      backgroundColor={backgroundColor}
      {...others}
    >
      <div>
        {username && (
          <StGreetings
            {...dataTestIdHandler(dataTestPrefix, 'username')}
            color={usernameColor}
          >
            Olá, {username}.
          </StGreetings>
        )}
        <span>Que bom te ver por aqui!</span>
      </div>
      <StPlanContainer>
        <StCurrentPlan>
          <span>Você está no</span>
          <StPlan borderColor={color}>
            <StPlanLabel>Plano</StPlanLabel>
            <StPlanName
              {...dataTestIdHandler(dataTestPrefix, 'plan-name')}
              color={color}
            >
              {name}
            </StPlanName>
          </StPlan>
        </StCurrentPlan>
        {hasPlansCompareLink ? (
          <StPlansCompare
            {...dataTestIdHandler(dataTestPrefix, 'plans-compare-link')}
            onClick={onPlansCompareClick}
          >
            <span>Comparar planos</span>
            <RightOutlined />
          </StPlansCompare>
        ) : (
          <StCongratulationsContainer
            {...dataTestIdHandler(dataTestPrefix, 'congratulations')}
          >
            <StCongratulations>Parabéns!</StCongratulations>
            <span>Você está no plano mais completo da Concil.</span>
            <StTrophyFilled />
          </StCongratulationsContainer>
        )}
      </StPlanContainer>
    </StContainer>
  );
};

export default CurrentPlanCard;
