import React, { useState } from 'react';
import { RegisterStep1 } from './RegisterStep1';
import { RegisterStep2 } from './RegisterStep2';
import { RegisterOtp } from './components/RegisterOtp/RegisterOtp';
import { RegisterSuccess } from './components/RegisterSuccess/RegisterSuccess';
import { RegisterError } from './components/RegisterError/RegisterError';
import { FadeWrapper, Wrapper } from './styled';
import { useRegisterCode } from './hooks/useRegisterCode';
import { useRegister } from './hooks/useRegister';
import { useCompanyName } from './hooks/useCompanyName';

export const Register = () => {
  const dataTestPrefix = 'register';
  const [step, setStep] = useState(1);
  const [accountData, setAccountData] = useState();
  const [otpValue, setOtpValue] = useState('');
  const [isOtpError, setOtpError] = useState(false);
  const [isRegisterErrorVisible, setRegisterErrorVisibility] = useState(false);
  const { onCreateAccount, loading: loadingCreateAccount } = useRegister();
  const {
    onSendRegisterCode,
    onValidateRegisterCode,
    loading: loadingRegisterCode,
  } = useRegisterCode();
  const {
    loading: companyNameLoading,
    companyName,
    companyNameError,
    fetchCompanyName,
  } = useCompanyName();

  const onFinishStep1 = (values) => {
    setAccountData(values);
    onSendRegisterCode({
      email: values?.email,
      onFinish: () => setStep(2),
    });
  };

  const onRequestNewCode = () => {
    onSendRegisterCode({ email: accountData?.email });
  };

  const onSubmitOtp = () => {
    onValidateRegisterCode({
      code: otpValue,
      onFinish: () => setStep(3),
      onError: () => setOtpError(true),
    });
  };

  const onClickBackStep = () => {
    setStep(1);
    setOtpValue('');
  };

  const onFetchCompanyName = (cnpj) => {
    fetchCompanyName({
      cnpj,
      token: otpValue,
    });
  };

  const onFinishStep2 = (values) => {
    setAccountData({ ...accountData, ...values });
    onCreateAccount({
      data: { ...accountData, ...values },
      token: otpValue,
      onFinish: () => setStep(4),
      onError: () => setRegisterErrorVisibility(true),
    });
  };

  return (
    <Wrapper>
      <FadeWrapper step={step}>
        {step === 1 && (
          <RegisterStep1
            initialValues={accountData}
            onFinish={onFinishStep1}
            loading={loadingRegisterCode}
            dataTestPrefix={dataTestPrefix}
          />
        )}
        {step === 2 && (
          <RegisterOtp
            otpValue={otpValue}
            setOtpValue={setOtpValue}
            isOtpError={isOtpError}
            setOtpError={setOtpError}
            onSubmit={onSubmitOtp}
            onRequestNewCode={onRequestNewCode}
            submitLoading={loadingRegisterCode}
          />
        )}
        {step === 3 && (
          <RegisterStep2
            initialValues={accountData}
            onFinish={onFinishStep2}
            loading={loadingCreateAccount}
            onClickBackStep={onClickBackStep}
            companyNameFetch={onFetchCompanyName}
            companyNameLoading={companyNameLoading}
            companyNameError={companyNameError}
            companyName={companyName}
            dataTestPrefix={dataTestPrefix}
          />
        )}
        {step === 4 && <RegisterSuccess />}
        <RegisterError
          isRegisterErrorVisible={isRegisterErrorVisible}
          setRegisterErrorVisibility={setRegisterErrorVisibility}
        />
      </FadeWrapper>
    </Wrapper>
  );
};
