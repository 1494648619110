import React from 'react';
import plans from 'constants/plans';
import skins from 'constants/skins';
import { PriceCard } from '../PriceCard/PriceCard';
import { plansDetails } from 'constants/plansDetails';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { Carousel } from './components/Carousel';
import { StCol } from './styled';

export const PlansContainer = ({ planId, skinId, dataTestPrefix }) => {
  const isCurrentPlan = (id) => id === planId;
  const isMostChosenTagVisible = planId !== plans.ADMINISTRE_PLUS;

  const filterPlans = (_plansDetails, _planId) => {
    const paidPlans = _plansDetails.filter(({ id }) => id !== plans.FREE);

    if (_planId === plans.FREE) {
      return _plansDetails.filter(({ id }) => id !== plans.PAG_GRATIS);
    }

    if (_planId === plans.PAG_GRATIS) {
      return _plansDetails.filter(({ id }) => id !== plans.FREE);
    }

    return paidPlans.filter(({ id }) => id !== plans.PAG_GRATIS);
  };

  const plansDetailsFiltered = filterPlans(plansDetails, planId);

  return (
    <StCol
      span={24}
      {...dataTestIdHandler(dataTestPrefix, 'price-cards-container')}
    >
      <Carousel dataTestPrefix={dataTestPrefix}>
        {plansDetailsFiltered.map((plan) => (
          <PriceCard
            key={plan?.id}
            plan={plan}
            resale={skinId === skins.CONCIL_REVENDA}
            currentPlan={isCurrentPlan(plan?.id)}
            isMostChosenTagVisible={isMostChosenTagVisible}
            {...dataTestIdHandler(
              dataTestPrefix,
              `price-card-${plan?.name}`.toLowerCase()
            )}
          />
        ))}
      </Carousel>
    </StCol>
  );
};
