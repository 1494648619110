import { createJWT } from 'helpers/jwt/jwt';
import { registerApi } from '../api';

const transformer = (apiResponse) => {
  const data = {
    data: apiResponse.data,
    error: apiResponse.error,
  };
  return data;
};

const token = createJWT({
  payload: { token: process.env.REACT_APP_REGISTER_API_KEY },
  secret: process.env.REACT_APP_REGISTER_JWT_SECRET,
});

export const sendRegisterCode = async ({ email }) => {
  const payload = {
    email,
  };

  const response = await registerApi(token).post(
    '/register-services/token/customer',
    payload
  );
  return transformer(response.data);
};

export const validateRegisterCode = async ({ code }) => {
  const response = await registerApi(token).get(
    `/register-services/token/customer?token=${code}`
  );
  return transformer(response.data);
};
