import React from 'react';
import * as St from './styled';

const ErrorCard = ({
  title = 'Dados indisponíveis',
  reason = 'Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação.',
  recommendation = 'Tente atualizar a página em alguns instantes.',
  ...others
}) => (
  <St.Wrapper {...others}>
    <St.ErrorTitle strong>{title}</St.ErrorTitle>
    <St.ErrorParagraph>{reason}</St.ErrorParagraph>
    <St.ErrorParagraph>{recommendation}</St.ErrorParagraph>
  </St.Wrapper>
);

export default ErrorCard;
