import { lambdaApi } from 'services/api';

const transformer = (response) => {
  return {
    code: response.code,
    message: response.message,
  };
};

export const postUserOnboarding = async () => {
  const response = await lambdaApi().post('/register-services/onboarding');
  return transformer(response.data);
};
