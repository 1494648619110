import React from 'react';
import { Input } from 'antd';

export const InputMaskCnpj = ({
  value = '',
  onChange = () => {},
  placeholder = '00.000.000/0000-00',
  ...rest
}) => {
  const formatCnpj = (cnpj) => {
    if (!cnpj) return '';

    let formattedCnpj = cnpj && cnpj.replace(/[^0-9]/g, '');

    if (formattedCnpj.length > 14) {
      formattedCnpj = formattedCnpj.slice(0, 14);
    }

    formattedCnpj = formattedCnpj.replace(/(\d{2})(\d)/, '$1.$2');
    formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1.$2');
    formattedCnpj = formattedCnpj.replace(/(\d{3})(\d)/, '$1/$2');
    formattedCnpj = formattedCnpj.replace(/(\d{4})(\d{1,2})$/, '$1-$2');

    return formattedCnpj;
  };
  const onLocalChange = (e) => {
    const targetValue = e.target.value;

    e.target.value = formatCnpj(targetValue);

    onChange(e);
  };

  return (
    <Input
      placeholder={placeholder}
      onChange={onLocalChange}
      value={value}
      {...rest}
    />
  );
};
