import React from 'react';
import { Input } from 'antd';

export const InputMaskCpf = ({
  value = '',
  onChange = () => {},
  placeholder = '000.000.000-00',
  ...rest
}) => {
  const formatCpf = (cpf) => {
    if (!cpf) return '';

    let formattedCpf = cpf && cpf.replace(/[^0-9]/g, '');

    if (formattedCpf.length > 11) {
      formattedCpf = formattedCpf.slice(0, 11);
    }

    formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2');
    formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2');
    formattedCpf = formattedCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return formattedCpf;
  };
  const onLocalChange = (e) => {
    const targetValue = e.target.value;

    e.target.value = formatCpf(targetValue);

    onChange(e);
  };

  return (
    <Input
      placeholder={placeholder}
      onChange={onLocalChange}
      value={value}
      {...rest}
    />
  );
};
