import { api } from '../api';

const transformer = (apiResponse) => {
  return {
    urls: apiResponse.data.urls,
  };
};

const getOptinUrlsFromAPI = async ({ acquirer, redirectURL = '' }) => {
  const config = {
    params: {
      redirectURL,
    },
  };

  const response = await api().get(
    `/integrations/optin/urls/${acquirer}`,
    config
  );
  return transformer(response.data);
};

export default getOptinUrlsFromAPI;
