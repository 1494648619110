import React from 'react';
import customHistory from 'helpers/history';
import { Routes, Route } from 'react-router-dom';
import { CustomRouter } from './CustomRouter';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { NotFound } from 'components/NotFound/NotFound';
import { Login } from 'components/Login';
import { ForgotPassword } from 'components/ForgotPassword';
import { CreatePassword } from 'components/CreatePassword';
import { RecoveryPassword } from 'components/RecoveryPassword';
import { Dashboard } from 'components/Dashboard';
import { SalesReports } from 'components/Reports/SalesReports';
import { SalesIntermediate } from 'components/Intermediate/Sales';
import { ManageUsers } from 'components/ManageUsers';
import { AmountsReceivableReports } from 'components/Reports/AmountsReceivableReports';
import { AmountsReceivableIntermediate } from 'components/Intermediate/AmountsReceivable';
import { BanksReports } from 'components/Reports/BanksReports';
import { BanksIntermediate } from 'components/Intermediate/Banks';
import { RatesIntermediate } from 'components/Intermediate/Rates';
import { ImportFiles } from 'components/ImportFiles';
import { Briefing } from 'components/Briefing';
import { OfxParameters } from 'components/OfxParameters';
import { BranchManagement } from 'components/BranchManagement';
import { AdjustmentReports } from 'components/Reports/AdjustmentReports';
import { AdvancementReports } from 'components/Reports/AdvancementReports';
import { DuedateReports } from 'components/Reports/DuedateReports';
import { RefundsReports } from 'components/Reports/RefundsReports';
import { PaymentsDivergencesIntermediate } from 'components/Intermediate/PaymentsDivergences';
import { PaymentDivergencesReports } from 'components/Reports/PaymentDivergences';
import { CnpjMaintenance } from 'components/CnpjMaintenance';
import { GeneralUserManagement } from 'components/GeneralUserManagement';
import { BankConciliation } from 'components/BankConciliation';
import { BankConciliationTransactions } from 'components/BankConciliationTransactions';
import { Radar } from 'components/Radar';
import { SalesReconciliationIntermediate } from 'components/Intermediate/SalesReconciliation';
import { SalesReconciliationReports } from 'components/Reports/SalesReconciliationReports';
import { Notifications } from 'components/Notifications';
import { Offers } from 'components/Offers';
import { AuthPartner } from 'components/Offers/components/AuthPartner';
import { PaymentsReconciliationIntermediate } from 'components/Intermediate/PaymentsReconciliation';
import { PaymentsReconciliationReports } from 'components/Reports/PaymentsReconciliationReports';
import { BanksReconciliationReports } from 'components/Reports/BanksReconciliationReports';
import { OnboardingManagement } from 'components/OnboardingManagement/OnboardingManagement';
import { ReconciliationRules } from 'components/ReconciliationRules';
import { RetroactiveConciliation } from 'components/RetroactiveConciliation';
import { CreateFees } from 'components/Fees/CreateFees';
import { Register } from 'components/Register/Register';
import { Fees } from 'components/Fees/Fees';
import { Plans } from 'components/Plans/Plans';
import {
  AdvancementPaymentsIntermediate,
  DuedatePaymentsIntermediate,
  AdjustmentPaymentsIntermediate,
  RefundsPaymentsIntermediate,
} from 'components/Intermediate/Payments';

export const AppRoutes = () => {
  return (
    <CustomRouter history={customHistory}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Login />} exact />
          <Route path="/criar-conta" element={<Register />} exact />
          <Route path="/esqueci-a-senha" element={<ForgotPassword />} exact />
          <Route path="/criar-senha" element={<CreatePassword />} exact />
          <Route
            path="/redefinir-a-senha"
            element={<RecoveryPassword />}
            exact
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} exact />
          <Route path="/planos" element={<Plans />} exact />
          <Route
            exact
            path="/vendas-intermediaria"
            element={<SalesIntermediate />}
          />
          <Route exact path="/vendas-relatorios" element={<SalesReports />} />
          <Route
            exact
            path="/bancos-intermediario"
            element={<BanksIntermediate />}
          />
          <Route exact path="/bancos-relatorios" element={<BanksReports />} />
          <Route exact path="/gerenciar-usuarios" element={<ManageUsers />} />
          <Route
            exact
            path="/gerenciar-empresas"
            element={<BranchManagement />}
          />
          <Route
            exact
            path="/consultar-acessos"
            element={<GeneralUserManagement />}
          />
          <Route
            exact
            path="/valores-a-receber"
            element={<AmountsReceivableIntermediate />}
          />
          <Route
            exact
            path="/valores-a-receber-relatorios"
            element={<AmountsReceivableReports />}
          />
          <Route
            exact
            path="/conciliacao-bancaria"
            element={<BankConciliation />}
          />
          <Route
            exact
            path="/analise-de-extrato-ofx-transacoes"
            element={<BankConciliationTransactions />}
          />
          <Route exact path="/importacao-arquivos" element={<ImportFiles />} />
          <Route exact path="/registro-de-implantacao" element={<Briefing />} />
          <Route
            exact
            path="/ajustes-intermediario"
            element={<AdjustmentPaymentsIntermediate />}
          />
          <Route
            exact
            path="/auditoria-pagamentos-intermediario"
            element={<PaymentsDivergencesIntermediate />}
          />
          <Route
            exact
            path="/auditoria-pagamentos-relatorios"
            element={<PaymentDivergencesReports />}
          />
          <Route
            exact
            path="/pagamentos-intermediario"
            element={<DuedatePaymentsIntermediate />}
          />
          <Route
            exact
            path="/antecipacao-intermediario"
            element={<AdvancementPaymentsIntermediate />}
          />
          <Route
            exact
            path="/cancchbk-intermediario"
            element={<RefundsPaymentsIntermediate />}
          />
          <Route
            exact
            path="/pagamentos-relatorios"
            element={<DuedateReports />}
          />
          <Route
            exact
            path="/antecipacao-relatorios"
            element={<AdvancementReports />}
          />
          <Route
            exact
            path="/ajustes-relatorios"
            element={<AdjustmentReports />}
          />
          <Route
            exact
            path="/cancchbk-relatorios"
            element={<RefundsReports />}
          />
          <Route exact path="/manutencao-cnpj" element={<CnpjMaintenance />} />
          <Route exact path="/regras-ofx" element={<OfxParameters />} />
          <Route
            exact
            path="/taxas-indicadores"
            element={<RatesIntermediate />}
          />
          <Route exact path="/radar" element={<Radar />} />
          <Route
            exact
            path="/conciliacao-vendas"
            element={<SalesReconciliationIntermediate />}
          />
          <Route
            exact
            path="/conciliacao-vendas-relatorios"
            element={<SalesReconciliationReports />}
          />
          <Route exact path="/notificacoes" element={<Notifications />} />
          <Route exact path="/banking" element={<Offers />} />
          <Route exact path="/autorizar-parceiro" element={<AuthPartner />} />
          <Route
            exact
            path="/conciliacao-pagamentos"
            element={<PaymentsReconciliationIntermediate />}
          />
          <Route
            exact
            path="/conciliacao-pagamentos-relatorios"
            element={<PaymentsReconciliationReports />}
          />
          <Route
            exact
            path="/conciliacao-bancaria-relatorios"
            element={<BanksReconciliationReports />}
          />
          <Route
            exact
            path="/regras-conciliacao"
            element={<ReconciliationRules />}
          />
          <Route
            exact
            path="/conciliacao-retroativa"
            element={<RetroactiveConciliation />}
          />
          <Route exact path="/taxas" element={<Fees />} />
          <Route path="/cadastro-taxas" element={<CreateFees />} />
          <Route
            path="/gerenciar-implantacao"
            element={<OnboardingManagement />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </CustomRouter>
  );
};
