import React from 'react';
import { StContainer, StImage, StTextContainer } from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const AcquirerEdiResponseCard = ({ ...rest }) => {
  const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const gearsIcon = `${assetsBaseURL}/img/gearsIcon.png`;

  const dataTestPrefix = 'acquirer-edi-response-card';

  return (
    <StContainer {...dataTestIdHandler(dataTestPrefix, 'container')} {...rest}>
      <StImage src={gearsIcon} {...dataTestIdHandler(dataTestPrefix, 'icon')} />
      <StTextContainer {...dataTestIdHandler(dataTestPrefix, 'text-container')}>
        <span>Estamos aguardando o retorno das adquirentes</span>
        <span>
          Confira o treinamento guiado clicando no botão “Comece aqui”, logo
          abaixo, no canto esquerdo da tela.
        </span>
      </StTextContainer>
    </StContainer>
  );
};
