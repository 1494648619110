import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin: 20px 0;
  justify-content: center;
`;

export const CarouselTrack = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-top: 42px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const PaginationContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
`;

export const StLeftOutlined = styled(LeftOutlined)`
  &&& {
    color: ${({ $disabled }) =>
      $disabled ? `${colors.gray6}` : `${colors.primary6}`};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'cursor')};
    font-size: 24px;
  }
`;

export const StRightOutlined = styled(RightOutlined)`
  &&& {
    color: ${({ $disabled }) =>
      $disabled ? `${colors.gray6}` : `${colors.primary6}`};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'cursor')};
    font-size: 24px;
  }
`;

export const StDots = styled.div`
  display: flex;
  gap: 8px;

  > span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.gray6};
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  > span.active {
    background-color: ${colors.primary6};
    width: 40px;
    border-radius: 4px;
    cursor: not-allowed;
  }
`;
