import { useCallback, useState } from 'react';
import { getCompanyName } from 'services/register/register';

export const useCompanyName = () => {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState(undefined);
  const [companyNameError, setCompanyNameError] = useState(false);

  const fetchCompanyName = useCallback(({ cnpj, token }) => {
    setLoading(true);
    setCompanyNameError(false);
    setCompanyName(undefined);

    const onCompanyNameError = () => {
      setCompanyNameError('Razão social não encontrada');
    };

    getCompanyName({ cnpj, token })
      .then(({ data }) => {
        const { razao_social: companyNameValue, situacao: status } = data;

        if (status === 'ATIVA' && companyNameValue) {
          setCompanyName(companyNameValue);
        } else {
          onCompanyNameError();
        }
      })
      .catch(() => onCompanyNameError())
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    companyName,
    companyNameError,
    fetchCompanyName,
  };
};
