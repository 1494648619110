import { Button, Modal, Typography } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
    width: 580px;
    height: 540px;
  }
  .ant-modal-body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const StContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const StImg = styled.img`
  width: 212px;
  height: 212px;
  margin: 48px 0 32px 0;
`;

export const StTitle = styled.h1`
  font-family: 'Roboto' sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin-bottom: 16px;
  color: ${colors.gray10};
`;

export const StDescription = styled.p`
  font-family: 'Roboto' sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: ${colors.gray10};
  margin-bottom: 16px;
`;

export const StPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const StDots = styled.div`
  display: flex;
  gap: 4px;

  > span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${colors.gray6};
    transition: all 0.2s ease-in-out;
  }

  > span.active {
    background-color: ${colors.primary6};
    width: 24px;
    border-radius: 4px;
  }
`;

export const StButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const StButton = styled(Button)`
  font-family: 'Roboto' sans-serif;
  width: 207px;
  height: 40px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

export const StText = styled(Typography.Text)`
  color: ${colors.gray9};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-top: 21px;

  cursor: pointer;
`;
