import { actionTypes } from 'store/ducks/optin/optinRequest/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  requestingMerchant: undefined,
  requestingMerchantLoading: false,
  isError: false,
  data: {},
  hasMore: true,
  success: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPTIN_REQUEST_START:
      return {
        ...state,
        loading: true,
        requestingMerchant: action.payload?.merchant,
        requestingMerchantLoading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
        success: false,
      };
    case actionTypes.OPTIN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        requestingMerchantLoading: false,
        error: undefined,
        data: action.payload.data,
        isError: false,
        errorMessage: undefined,
        success: true,
      };
    case actionTypes.OPTIN_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        requestingMerchantLoading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
        data: {},
        success: false,
      };
    default:
      return state;
  }
};

export default reducer;
