import { Form, Space, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';

const { Item } = Form;
const { Text } = Typography;

export const StSpace = styled(Space)`
  background-color: ${(props) => (props?.$breakpoint?.lg ? '' : colors.gray1)};
  padding: ${(props) => (props?.$breakpoint?.lg ? '' : '2rem')};
  border-radius: 5px;
`;

export const StRow = styled(Row)`
  height: 100%;
  background-color: ${colors.gray1};
  border-radius: 5px;
`;

export const StFormItem = styled(Item)`
  max-width: 269;
  color: ${colors.gray8};

  &&& {
    .ant-form-item-label > label {
      color: ${colors.gray8};
    }
  }
`;

export const StText = styled(Text)`
  color: ${colors.gray14};
  font-family: 'Roboto' sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;

export const StLink = styled(Link)`
  &&& {
    color: ${colors.primary6};
    font-family: 'Roboto' sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;
