import { registerApi } from '../api';
import { cnpj } from 'helpers/cnpj';

const transformer = (apiResponse) => {
  const data = {
    data: apiResponse.data,
    error: apiResponse.error,
  };
  return data;
};

export const register = async ({ data, token }) => {
  const { name, rg, cpf, email, phone, cnpj: cnpjValue, companyName } = data;
  const payload = {
    name: name.trim(),
    rg,
    cpf,
    email,
    phone,
    cnpj: cnpjValue,
    company_name: companyName,
    origin: 'web',
  };

  const response = await registerApi(token).post(
    '/register-services/register/customer',
    payload
  );
  return transformer(response.data);
};

export const getCompanyName = async ({ cnpj: cnpjValue, token }) => {
  const payload = cnpj.strip(cnpjValue);
  const response = await registerApi(token).get(
    `/external-api-connector/document-verifier/cnpj?cnpj=${payload}`
  );
  return transformer(response.data);
};
