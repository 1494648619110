import React from 'react';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  StButton,
  StContainer,
  StContent,
  StContentContainer,
  StFooter,
  StInfoCircleOutlined,
  StLink,
  StTitle,
  StTitleContainer,
} from './styled';

export const RegisterError = ({
  isRegisterErrorVisible,
  setRegisterErrorVisibility,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      width={520}
      centered
      closable={false}
      footer={null}
      maskClosable={false}
      open={isRegisterErrorVisible}
    >
      <StContainer>
        <StTitleContainer>
          <StInfoCircleOutlined />
          <StTitle>Ocorreu um erro!</StTitle>
        </StTitleContainer>
        <StContentContainer>
          <StContent>
            Revise os dados informados ou volte para a tela inicial e faça o
            login novamente. Se precisar, redefina sua senha ou fique à vontade
            para nos contatar:
          </StContent>
          <StLink href="mailto:meajuda@concil.com.br">
            meajuda@concil.com.br
          </StLink>
        </StContentContainer>
        <StFooter>
          <StButton onClick={() => setRegisterErrorVisibility(false)}>
            Fechar
          </StButton>
          <Button type="primary" onClick={() => navigate('/')}>
            Ir para tela de login
          </Button>
        </StFooter>
      </StContainer>
    </Modal>
  );
};
