import React, { useEffect, useRef, useState } from 'react';
import {
  CarouselContainer,
  CarouselTrack,
  PaginationContainer,
  StDots,
  StLeftOutlined,
  StRightOutlined,
} from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const Carousel = ({ children, dataTestPrefix }) => {
  const trackRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const checkOverflow = () => {
    if (trackRef.current) {
      const isOverflowing =
        trackRef.current.scrollWidth > trackRef.current.clientWidth;
      setIsScrollable(isOverflowing);

      if (isOverflowing) {
        const _totalPages = Math.ceil(
          trackRef.current.scrollWidth / trackRef.current.clientWidth
        );
        setTotalPages(_totalPages);

        trackRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        setCurrentPage(0);
      } else {
        setTotalPages(1);
        setCurrentPage(0);
      }
    }
  };

  const goToPage = (page) => {
    if (currentPage === page) return;

    if (trackRef.current) {
      const trackWidth = trackRef.current.clientWidth;
      trackRef.current.scrollTo({
        left: trackWidth * page,
        behavior: 'smooth',
      });
      setCurrentPage(page);
    }
  };

  const handleLeftClick = () => {
    if (currentPage > 0) {
      goToPage(currentPage - 1);
    }
  };

  const handleRightClick = () => {
    if (currentPage < totalPages - 1) {
      goToPage(currentPage + 1);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <>
      <CarouselContainer
        {...dataTestIdHandler(dataTestPrefix, 'carousel-container')}
      >
        <CarouselTrack
          ref={trackRef}
          {...dataTestIdHandler(dataTestPrefix, 'carousel-track')}
        >
          {children}
        </CarouselTrack>
      </CarouselContainer>
      <PaginationContainer
        {...dataTestIdHandler(dataTestPrefix, 'pagination-container')}
      >
        {isScrollable && (
          <>
            <StLeftOutlined
              onClick={handleLeftClick}
              {...dataTestIdHandler(dataTestPrefix, 'pagination-left')}
              $disabled={currentPage <= 0}
            />

            <StDots>
              {Array.from({ length: totalPages }).map((_, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={() => goToPage(index)}
                  className={index === currentPage ? 'active' : ''}
                  {...dataTestIdHandler(
                    dataTestPrefix,
                    `pagination-dot-${index}`
                  )}
                />
              ))}
            </StDots>

            <StRightOutlined
              onClick={handleRightClick}
              {...dataTestIdHandler(dataTestPrefix, 'pagination-right')}
              $disabled={currentPage >= totalPages - 1}
            />
          </>
        )}
      </PaginationContainer>
    </>
  );
};
