import React, { useContext } from 'react';
import skins from 'constants/skins';
import { Grid, Row } from 'antd';
import { StPlansContainer } from './styled';
import { PlansContainer } from './components/PlansContainer/PlansContainer';
import { PlanComparison } from './components/PlanComparison/PlanComparison';
import { PlansHeader } from './components/PlansHeader/PlansHeader';
import { PermissionsContext } from 'routes/PrivateRoute';

export const Plans = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const { permissions } = useContext(PermissionsContext);
  const planId = permissions?.plan_id;
  const skinId = permissions?.skin_id;

  const dataTestPrefix = 'plans';

  return (
    <StPlansContainer $breakpoint={breakpoint}>
      <Row>
        <PlansHeader dataTestPrefix={dataTestPrefix} />
      </Row>
      <Row>
        <PlansContainer
          planId={planId}
          skinId={skinId}
          dataTestPrefix={dataTestPrefix}
        />
      </Row>
      {skinId !== skins.CONCIL_REVENDA && (
        <Row>
          <PlanComparison dataTestPrefix={dataTestPrefix} />
        </Row>
      )}
    </StPlansContainer>
  );
};
