import React from 'react';
import {
  StContainer,
  StContainerSuccess,
  StContent,
  StLink,
  StTitle,
} from './styled';

export const RegisterSuccess = () => {
  return (
    <StContainer>
      <StContainerSuccess>
        <StTitle>Enviaremos um e-mail para você validar sua conta.</StTitle>
        <StContent>
          Em até 24 horas, você receberá um e-mail com as próximas instruções
          para concluir o seu cadastro.
        </StContent>
        <StContent>
          Caso não receba dentro desse prazo, fique à vontade para nos contatar:
        </StContent>
        <StLink href="mailto:meajuda@concil.com.br">
          meajuda@concil.com.br
        </StLink>
      </StContainerSuccess>
    </StContainer>
  );
};
