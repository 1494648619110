import { useCallback, useState } from 'react';
import { register } from 'services/register/register';

export const useRegister = () => {
  const [loading, setLoading] = useState(false);

  const onCreateAccount = useCallback(
    ({ data, token, onFinish = () => {}, onError = () => {} }) => {
      setLoading(true);

      register({ data, token })
        .then(() => onFinish())
        .catch(() => onError())
        .finally(() => setLoading(false));
    },
    []
  );

  return {
    loading,
    onCreateAccount,
  };
};
