import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StContainerSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 387px;
  padding: 32px;
  background-color: ${colors.gray1};
  border-radius: 4px;
`;

export const StTitle = styled.h1`
  margin: 0 32px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: ${colors.gray8};
`;

export const StContent = styled.span`
  display: block;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${colors.gray8};
`;

export const StLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${colors.primary6};
`;
