import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from 'styles/colors';
import { InfoCircleOutlined } from '@ant-design/icons';

export const StContainer = styled.div`
  padding: 6px;
`;

export const StTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 40px 24px;
`;

export const StFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-right: 16px;
  font-size: 21px;
  color: ${colors.gold6};
`;

export const StTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.gray9};
`;

export const StContent = styled.span`
  display: block;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.gray8};
`;

export const StLink = styled.a`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${colors.primary6};
`;

export const StButton = styled(Button)`
  margin-right: 16px;
`;
