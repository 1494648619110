/* eslint-disable react/destructuring-assignment,react/no-this-in-sfc, react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react';
import { Grid, Button } from 'antd';
import { MiniLogo } from 'commons/MiniLogo';
import {
  CommentOutlined,
  LeftOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { UserMenu } from 'commons/Header/components';
import customHistory from 'helpers/history';
import {
  StContainer,
  StRow,
  StCol,
  StBranchButton,
  StNotificationsMask,
  StNotificationsModal,
  StMenu,
} from './styled';
import LeftDrawerOptions from './components/LeftDrawerOptions/LeftDrawerOptions';
import { HeaderNotifications } from '../../components/Notifications/components/HeaderNotifications/HeaderNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { postMovideskLogin } from '../../services/movidesk';
import {
  amountsReceivableDetailsFiltersZeroLength,
  updateAmountsReceivableDetailsFilters,
} from 'store/ducks/filters/details/amountsReceivableDetailsFilters/actions';
import {
  salesDetailsFiltersZeroLength,
  updateSalesDetailsFilters,
} from 'store/ducks/filters/details/salesDetailsFilters/actions';
import {
  paymentDivergencesDetailsFiltersZeroLength,
  updatePaymentDivergencesDetailsFilters,
} from 'store/ducks/filters/details/paymentDivergencesDetailsFilters/actions';
import {
  banksDetailsFiltersZeroLength,
  updateBanksDetailsFilters,
} from 'store/ducks/filters/details/banksDetailsFilters/actions';
import {
  duedateDetailsFiltersZeroLength,
  updateDuedateDetailsFilters,
} from 'store/ducks/filters/details/duedateDetailsFilters/actions';
import {
  advancementDetailsFiltersZeroLength,
  updateAdvancementDetailsFilters,
} from 'store/ducks/filters/details/advancementDetailsFilters/actions';
import {
  adjustmentDetailsFiltersZeroLength,
  updateAdjustmentDetailsFilters,
} from 'store/ducks/filters/details/adjustmentDetailsFilters/actions';
import {
  refundDetailsFiltersZeroLength,
  updateRefundDetailsFilters,
} from 'store/ducks/filters/details/refundDetailsFilters/actions';
import {
  getSessionClientCode,
  getSessionClientName,
  getSessionFirstName,
  getSessionSurname,
  getSessionUsername,
  getSessionClientId,
  getSessionUsernameId,
} from 'helpers/sessionService';
import { getNotificationStart } from 'store/ducks/notifications/actions';
import { colors } from 'styles/colors';
import { ClientListModal } from '../ClientListModal/ClientListModal';
import { listOfClientsStart } from 'store/ducks/listOfClients/actions';
import errorHandler from 'helpers/errorHandler';
import { NotificationButton } from './components/NotificationButton';
import { UserMenuButton } from './components/UserMenuButton';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { movideskChat } from 'externalScripts/movidesk';
import skins from 'constants/skins';
import { userGuidingIntegrationData } from 'helpers/userGuidingIntegrationData';

const Header = () => {
  const dispatch = useDispatch();
  const store = getSessionClientName();
  const clientId = getSessionClientId();
  const clientCode = getSessionClientCode();
  const clientEmail = getSessionUsername();
  const userFirstName = getSessionFirstName();
  const userSurname = getSessionSurname();
  const userFullName = `${userFirstName || ''} ${userSurname || ''}`.trim();
  const permissionsData = useSelector((state) => state.permissions.data);
  const permissionsSuccess = useSelector((state) => state.permissions.success);
  const { plan_logo: planLogo } = useSelector(
    (state) => state.listOfClients.currentClient
  );
  const [toggleMenuLeft, setToggleMenuLeft] = useState(false);
  const [toggleMenuRight, setToggleMenuRight] = useState(false);
  const [modalVisible, setVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [localUsernameIdState, setLocalUsernameIdState] = useState('');
  const usernameId = getSessionUsernameId();

  const [notificationsAreVisible, setNotificationsAreVisible] = useState(false);
  const hasUnreadNotifications = useSelector(
    (state) => state.notifications.hasUnreadNotifications
  );
  const notificationPageFromStore = useSelector(
    (state) => state.notifications.notificationPage
  );
  const notificationPerPageFromStore = useSelector(
    (state) => state.notifications.notificationPerPage
  );
  const {
    user_id: userId,
    scope_id: scopeId,
    skin_id: skinId,
  } = permissionsData;

  const dataTestPrefix = 'header';
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const branchName = clientCode
    ? `${clientCode} ${store}`
    : 'Erro ao buscar dados';

  if (clientCode && skinId && skinId !== skins.CONCIL_REVENDA) {
    movideskChat();
  }

  const closeMenus = () => {
    if (toggleMenuRight) {
      setToggleMenuRight(false);
    }
    if (toggleMenuLeft) {
      setToggleMenuLeft(false);
    }
  };

  const goToDashboard = () => {
    customHistory.push('/dashboard');
    setNotificationsAreVisible(false);
  };

  const goToNotifications = () => {
    setNotificationsAreVisible(!notificationsAreVisible);
    customHistory.push('/notificacoes');
  };

  const getNotification = () => {
    if (permissionsSuccess) {
      dispatch(
        getNotificationStart({
          userId,
          scopeId,
          clientId,
          page: notificationPageFromStore,
          perPage: notificationPerPageFromStore,
        })
      );
    }
  };

  useEffect(() => {
    /* The if statement below removes the old localStorage key if it still exists at the user's browser. We're removing it since we won't be using this key anymore and will be using the app's store instead. */
    if (localStorage.getItem('notificationPage')) {
      localStorage.removeItem('notificationPage');
    }

    /* The getNotification's dispatch must be kept exactly where it is.
    It must be "called" here in this useEffect to avoid having to wait
    15 seconds to make the first call at the setInterval below
    and render the notifications. Also, do not remove the clearInterval! */
    getNotification();
    const intervalId = setInterval(() => getNotification(), 15000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    permissionsSuccess,
    notificationPageFromStore,
    notificationPerPageFromStore,
  ]);

  const goBack = () => {
    // These dispatches are necessary in order to avoid
    // filter state clusterings between screens.
    dispatch(salesDetailsFiltersZeroLength());
    dispatch(updateSalesDetailsFilters([]));
    dispatch(paymentDivergencesDetailsFiltersZeroLength());
    dispatch(updatePaymentDivergencesDetailsFilters([]));
    dispatch(amountsReceivableDetailsFiltersZeroLength());
    dispatch(updateAmountsReceivableDetailsFilters([]));
    dispatch(banksDetailsFiltersZeroLength());
    dispatch(updateBanksDetailsFilters([]));
    dispatch(duedateDetailsFiltersZeroLength());
    dispatch(updateDuedateDetailsFilters([]));
    dispatch(advancementDetailsFiltersZeroLength());
    dispatch(updateAdvancementDetailsFilters([]));
    dispatch(adjustmentDetailsFiltersZeroLength());
    dispatch(updateAdjustmentDetailsFilters([]));
    dispatch(refundDetailsFiltersZeroLength());
    dispatch(updateRefundDetailsFilters([]));
    customHistory.go(-1);
  };

  const changeToggleLeftMenu = () => {
    if (toggleMenuRight) {
      setToggleMenuRight(false);
    }
    setToggleMenuLeft(!toggleMenuLeft);
    setNotificationsAreVisible(false);
  };

  const changeToggleRightMenu = () => {
    if (toggleMenuLeft) {
      setToggleMenuLeft(false);
    }
    setToggleMenuRight(!toggleMenuRight);
    setNotificationsAreVisible(false);
  };

  const openModal = () => {
    closeMenus();
    setVisible(true);
    setNotificationsAreVisible(false);
  };

  const menuCentralAjuda = () => {
    setSelectedKeys([]);
    window
      .open('https://atendimento.concil.com.br/kb/article/227692/', '_blank')
      .focus();
  };

  const menuChamadosChat = () => {
    const payload = {
      username: clientEmail,
      client_code: clientCode,
      store: store,
    };
    postMovideskLogin(payload)
      .then((response) => {
        const { data } = response;
        if (window) {
          if (data.message) {
            errorHandler(data?.message);
          } else {
            window.open(data.redirect_to, '_blank').focus();
            setSelectedKeys([]);
          }
        }
      })
      .catch(() => {
        setSelectedKeys([]);
        return errorHandler(
          'Por favor, habilite a função de exibir pop-up neste site para abrir um chamado'
        );
      });
  };

  const items = [
    {
      label: (
        <QuestionCircleOutlined
          style={{ padding: '0 14px', fontSize: '16px', color: colors.gray1 }}
        />
      ),
      key: 'SubMenu',
      children: [
        {
          type: 'group',
          label: 'Atendimento Concil',
          children: [
            {
              key: 'helpCentralAjuda',
              label: 'Central de ajuda',
              icon: (
                <QuestionCircleOutlined
                  style={{
                    fontSize: '16px',
                    color: colors.gray9,
                  }}
                />
              ),
              onClick: menuCentralAjuda,
            },
            {
              key: 'helpChamadosChat',
              label: 'Abrir um chamado',
              icon: (
                <CommentOutlined
                  style={{
                    fontSize: '16px',
                    color: colors.gray9,
                  }}
                />
              ),
              onClick: menuChamadosChat,
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(
      listOfClientsStart({
        page: 1,
        isReconciliationRules: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalUsernameIdState(usernameId);

    if (localUsernameIdState !== '' && clientCode) {
      userGuidingIntegrationData(localUsernameIdState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUsernameIdState, clientCode]);

  return (
    <>
      <StContainer
        $breakpoint={breakpoint}
        {...dataTestIdHandler(dataTestPrefix, 'container')}
      >
        <StRow
          $breakpoint={breakpoint}
          onClick={() => {
            closeMenus();
          }}
        >
          <StCol>
            <Button
              type="text"
              style={{
                color: colors.gray1,
                paddingRight: 15,
              }}
              onClick={changeToggleLeftMenu}
              {...dataTestIdHandler(dataTestPrefix, 'toggle-left-menu')}
            >
              <MenuOutlined style={{ fontSize: '20px' }} />
            </Button>
            <Button type="link" onClick={goToDashboard} style={{ padding: 0 }}>
              <MiniLogo />
            </Button>
            <Button
              type="link"
              style={{
                display:
                  breakpoint?.lg &&
                  customHistory.location.pathname !== '/dashboard'
                    ? 'block'
                    : 'none',
                color: colors.gray1,
              }}
              onClick={goBack}
            >
              <LeftOutlined />
              Voltar
            </Button>
          </StCol>
          <StCol>
            <StBranchButton
              {...dataTestIdHandler(dataTestPrefix, 'branch-name')}
              type="dashed"
              block
              onClick={() => {
                openModal();
              }}
            >
              <SwapOutlined style={{ fontSize: '15px' }} />
              {branchName}
            </StBranchButton>
            {modalVisible && (
              <ClientListModal visible={modalVisible} setVisible={setVisible} />
            )}
          </StCol>
          <StCol>
            <StMenu
              items={items}
              mode="horizontal"
              selectedKeys={selectedKeys}
            />

            <NotificationButton
              hasUnreadNotifications={hasUnreadNotifications}
              notificationsAreVisible={notificationsAreVisible}
              setNotificationsAreVisible={setNotificationsAreVisible}
              dataTestPrefix={dataTestPrefix}
            />

            <UserMenuButton
              changeToggleRightMenu={changeToggleRightMenu}
              toggleMenuRight={toggleMenuRight}
              dataTestPrefix={dataTestPrefix}
            />
          </StCol>
        </StRow>
        <LeftDrawerOptions
          collapsed={toggleMenuLeft}
          onColappsed={changeToggleLeftMenu}
          clientEmail={clientEmail}
          store={store}
          logoLink={planLogo}
          closeMenus={closeMenus}
        />
        <UserMenu
          collapsed={toggleMenuRight}
          onColappsed={changeToggleRightMenu}
          userFullName={userFullName}
          userEmail={clientEmail}
          branchName={store}
          logoLink={planLogo}
          skinId={skinId}
        />
        {notificationsAreVisible && (
          <>
            <StNotificationsMask
              notificationsAreVisible={notificationsAreVisible}
              onClick={() =>
                setNotificationsAreVisible(!notificationsAreVisible)
              }
            />
            <StNotificationsModal>
              <HeaderNotifications goToNotifications={goToNotifications} />
            </StNotificationsModal>
          </>
        )}
      </StContainer>
    </>
  );
};

export default Header;
