import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 127px;
  padding: 20px 32px;
  border-radius: 4px;
  font-size: 16px;

  background-color: ${colors.gold1};
  box-shadow: 0px 3px 6px 0px #0000000a;
`;

export const StImage = styled.img`
  width: 100px;
  height: 83px;
`;

export const StTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  color: ${colors.gray9};

  > span:first-child {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }

  > span:nth-child(2) {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
