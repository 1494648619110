import React from 'react';
import { Input } from 'antd';

const InputMaskPhone = ({
  value = '',
  onChange = () => {},
  placeholder = '(00) 00000-0000',
  ...rest
}) => {
  const formatPhone = (phone) => {
    if (!phone) return '';

    let formattedValue = phone.replace(/\D/g, '');

    if (formattedValue.length > 11) {
      formattedValue = formattedValue.slice(0, 11);
    }

    if (formattedValue.length < 1) {
      return '';
    }

    if (formattedValue.length <= 2) {
      return `(${formattedValue}`;
    }

    formattedValue = formattedValue.replace(/^(\d{2})(\d{0,4})$/, '($1) $2');
    formattedValue = formattedValue.replace(
      /^(\d{2})(\d{4})(\d{0,4})$/,
      '($1) $2-$3'
    );
    formattedValue = formattedValue.replace(
      /^(\d{2})(\d{5})(\d{0,4})$/,
      '($1) $2-$3'
    );

    return formattedValue;
  };

  const onLocalChange = (e) => {
    const targetValue = e.target.value;
    e.target.value = formatPhone(targetValue);
    onChange(e);
  };

  return (
    <Input
      placeholder={placeholder}
      onChange={onLocalChange}
      value={value}
      {...rest}
    />
  );
};

export default InputMaskPhone;
