import React, { createContext, useEffect, useState } from 'react';
import { Grid } from 'antd';
import { CnpjModal } from './Components/CnpjModal';
import { ContentTabs } from './Components/ContentTabs';
import { Filters } from './Components/Filters';
import { StContainer } from './styled';
import { useSearchParams } from 'react-router-dom';
import customHistory from 'helpers/history';

export const CnpjMaintenanceContext = createContext({});

const CnpjMaintenance = () => {
  const [searchParams] = useSearchParams();
  const modal = searchParams.get('modal');
  const tab = searchParams.get('aba');
  const hasState = searchParams.has('state');
  const hasCode = searchParams.has('code');

  const [merchantHasPosted, setMerchantHasPosted] = useState(false);
  const [currentStoreToUpdate, setCurrentStoreToUpdate] = useState({});

  const [addModalVisibilityAdquirente, setAddModalVisibilityAdquirente] =
    useState(false);
  const [addModalVisibilityCnpj, setAddModalVisibilityCnpj] = useState(false);
  const [
    addModalVisibilityLettersAndOptins,
    setAddModalVisibilityLettersAndOptins,
  ] = useState(false);
  const [isNextButtonLocked, setIsNextButtonLocked] = useState(false);

  const [activeTab, setActiveTab] = useState('stores');
  const [searchFilter, setSearchFilter] = useState(null);

  const openAddModal = (screen) => {
    if (screen === 0) {
      setAddModalVisibilityCnpj(true);
    } else if (screen === 1) {
      setAddModalVisibilityAdquirente(true);
    } else if (screen === 5) {
      setAddModalVisibilityLettersAndOptins(true);
    }
  };
  const closeAddModal = () => {
    setAddModalVisibilityAdquirente(false);
    setAddModalVisibilityCnpj(false);
    setAddModalVisibilityLettersAndOptins(false);
  };

  const onChangeSelectFilter = (e) => {
    if (
      e === 'Buscar CNPJ ou apelido da loja' ||
      e === 'Buscar estabelecimento' ||
      e === undefined ||
      e === ''
    ) {
      setSearchFilter(null);
    } else {
      setSearchFilter(e);
    }
  };

  const onTabClick = (key) => {
    setActiveTab(key);
    onChangeSelectFilter('');
  };

  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (tab === 'adquirentes') {
      onTabClick('acquirers');
    }

    if (tab === 'lojas') {
      onTabClick('stores');
    }

    if (modal === 'adquirentes') {
      openAddModal(1);
    }

    if (modal === 'lojas') {
      openAddModal(0);
    }

    if (hasState && hasCode) {
      openAddModal(5);
      return;
    }

    customHistory.replace('/manutencao-cnpj');
    // eslint-disable-next-line
  }, [tab, modal, hasState, hasCode]);

  return (
    <CnpjMaintenanceContext.Provider
      value={{
        merchantHasPosted,
        setMerchantHasPosted,
        currentStoreToUpdate,
        setCurrentStoreToUpdate,
        activeTab,
        closeAddModal,
      }}
    >
      <StContainer $breakpoint={breakpoint}>
        <Filters
          title="CNPJs e Códigos de Estabelecimento"
          onChangeSelectFilter={onChangeSelectFilter}
          onTabClick={onTabClick}
          activeTab={activeTab}
          openAddModal={openAddModal}
          searchFilter={searchFilter}
        />
        <ContentTabs
          onTabClick={onTabClick}
          activeTab={activeTab}
          closeModal={closeAddModal}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
        <CnpjModal
          visibility={addModalVisibilityCnpj}
          screen={0}
          closeModal={closeAddModal}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
        />
        <CnpjModal
          visibility={addModalVisibilityAdquirente}
          screen={1}
          closeModal={closeAddModal}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
        />

        <CnpjModal
          visibility={addModalVisibilityLettersAndOptins}
          screen={4}
          closeModal={closeAddModal}
          isNextButtonLocked={isNextButtonLocked}
          setIsNextButtonLocked={setIsNextButtonLocked}
        />
      </StContainer>
    </CnpjMaintenanceContext.Provider>
  );
};

export default CnpjMaintenance;
