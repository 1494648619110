import React from 'react';
import { Input } from 'antd';

const InputMaskRg = ({
  value = '',
  onChange = () => {},
  placeholder = '00.000.000-0',
  ...others
}) => {
  const formatRG = (rg) => {
    if (!rg) return '';

    let formattedValue = rg.replace(/[^0-9a-zA-Z]/g, '');

    if (formattedValue.length > 9) {
      formattedValue = formattedValue.slice(0, 9);
    }

    formattedValue = formattedValue.replace(/^(\w{2})(\w)/, '$1.$2');
    formattedValue = formattedValue.replace(
      /^(\w{2})\.(\w{3})(\w)/,
      '$1.$2.$3'
    );
    formattedValue = formattedValue.replace(
      /^(\w{2})\.(\w{3})\.(\w{3})(\w)/,
      '$1.$2.$3-$4'
    );

    return formattedValue;
  };

  const onLocalChange = (e) => {
    const targetValue = e.target.value;
    const formattedRg = formatRG(targetValue);
    e.target.value = formattedRg;

    onChange(e);
  };

  return (
    <Input
      value={value}
      onChange={onLocalChange}
      placeholder={placeholder}
      {...others}
    />
  );
};

export default InputMaskRg;
