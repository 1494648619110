import dayjs from 'dayjs';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Typography, Button, Modal } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import customHistory from '../../../../helpers/history';
import { Loadable } from '../../../../commons/Loadable';
import { closeBriefing } from '../../../../services/briefing';
import { BriefingLettersAndOptins } from 'components/Briefing/Components/BriefingLettersAndOptins';
import { BriefingStoresRegister } from 'components/Briefing/Components/BriefingStoresRegister';
import { BriefingAcquirersPointOfSale } from 'components/Briefing/Components/BriefingAcquirersPointOfSale';
import { BriefingAcquirersVoucher } from 'components/Briefing/Components/BriefingAcquirersVoucher';
import { BriefingMerchantsRegister } from 'components/Briefing/Components/BriefingMerchantsRegister';
import { BriefingReview } from 'components/Briefing/Components/BriefingReview';
import { updateBriefingStepStart } from 'store/ducks/updateBriefingStep/actions';
import { postClientAcquirerStart } from 'store/ducks/clientAcquirer/actions';
import { fileIdentificationStart } from 'store/ducks/client/actions';
import { getSessionClientId } from 'helpers/sessionService';
import { CnpjMaintenanceContext } from 'components/CnpjMaintenance/CnpjMaintenance';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { StModal } from './styled';
import { usePermissions } from 'utils/hooks/usePermissions';
import {
  getBriefingStart,
  postBriefingStart,
  putBriefingStart,
} from '../../../../store/ducks/briefing/actions';

let briefing;

const CnpjModal = ({
  visibility,
  screen,
  closeModal,
  isNextButtonLocked,
  setIsNextButtonLocked,
  acquirerSelected,
}) => {
  const { fetchPermissions } = usePermissions();
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const { merchantHasPosted, setMerchantHasPosted } = useContext(
    CnpjMaintenanceContext
  );

  const dispatch = useDispatch();
  const briefingData = useSelector((state) => state.briefing.briefing);

  const clientAcquirers = useSelector((state) => state.acquirer.acquirers);
  const acquirerData = useSelector((state) => state.acquirer.acquirersVouchers);
  const loadingActionsBriefing = useSelector(
    (state) => state.briefing.loadingActions
  );
  const [isVoucherNotAccepted, setIsVoucherNotAccepted] = useState(false);

  const [currentPage, setCurrentPage] = useState(screen);
  const [acquirers, setAcquirers] = useState([]);
  let savedDate;
  const childRef = useRef();

  const CnpjPage = {
    StoresRegister: 0,
    AcquirersPointOfSale: 1,
    AcquirersVouchers: 2,
    MerchantsRegister: 3,
    LettersAndOptins: 4,
    Review: 5,
  };

  // The "loadBriefing" function below and the useEffect hook that calls it are
  // both necessary so that the "onFinishBriefing" function works properly in this file.
  const loadBriefing = () => {
    dispatch(getBriefingStart({}));
  };

  useEffect(() => {
    if (!loadingActionsBriefing) {
      loadBriefing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadingActionsBriefing]);

  const goToNextPage = () => {
    if (currentPage === 1 || currentPage === 2 || currentPage === 3) {
      if (acquirerData) {
        const filteredAcquirers = acquirerData.acquirers.filter((acquirer) => {
          if (clientAcquirers) {
            return clientAcquirers?.find(
              (ca) => ca.acquirer_id === acquirer.acquirer_id
            );
          }
          return false;
        });

        acquirers.forEach((acquirer) => {
          filteredAcquirers.forEach((filteredAcq) => {
            if (
              acquirer?.checked &&
              acquirer?.acquirer_id !== filteredAcq?.acquirer_id &&
              !filteredAcq?.checked
            ) {
              const data = {
                acquirer_id: acquirer.acquirer_id,
                client_id: getSessionClientId(),
              };
              dispatch(postClientAcquirerStart(data));
            }
          });
        });
      }
    }
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => setCurrentPage(currentPage - 1);

  if (briefingData) {
    briefing = briefingData?.briefing;
    savedDate = briefing?.update_datetime ?? briefing?.create_datetime;
  }

  const afterValidatePersonalData = async (dataHasChanged) => {
    if (dataHasChanged) {
      const data = {
        briefing_id: briefing?.briefing_id,
        origin: 'MANUTENCAO',
        client_id: getSessionClientId(),
      };
      if (briefing && briefing?.briefing_id) {
        await dispatch(putBriefingStart(data));
      } else {
        await dispatch(postBriefingStart(data));
      }
    }
    goToNextPage();
  };

  const onFinishBriefing = async () => {
    const data = { briefing_id: briefing?.briefing_id, origin: 'MANUTENCAO' };

    dispatch(
      fileIdentificationStart({
        client_id: Number(getSessionClientId()),
      })
    );

    closeBriefing(data).then(() => {
      fetchPermissions();

      if (!isFeatureAvailable({ key: 'cnpjMaintenence-fees-redirect' })) {
        customHistory.push('/dashboard');
        return;
      }

      Modal.confirm({
        title: 'Cadastro concluído',
        content: (
          <div>
            Agora vamos solicitar os arquivos para as adquirentes e em breve
            você poderá iniciar a gestão da sua empresa no Concil Card.
            <br />
            <br />
            Seu próximo passo é cadastrar suas taxas, para que a gente possa
            conciliar todas suas vendas e recebimentos. Você ainda poderá fazer
            isso em outro momento, se preferir.
          </div>
        ),
        icon: <CheckCircleFilled style={{ color: 'green' }} />,
        maskClosable: false,
        keyboard: false,
        okText: 'Cadastrar taxas',
        onOk: () => {
          customHistory.push('/taxas');
        },
        cancelText: 'Sair',
        onCancel: (close) => {
          customHistory.push('/dashboard');
          close();
        },
      });
    });
  };

  useEffect(() => {
    dispatch(updateBriefingStepStart(screen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen]);

  const renderCurrentPage = () => {
    const isLocked = false;
    switch (currentPage) {
      case CnpjPage.StoresRegister:
        return (
          <BriefingStoresRegister
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
            throughModal
            afterValidateNextPage={afterValidatePersonalData}
            isCNPJMaintenance
          />
        );
      case CnpjPage.AcquirersPointOfSale:
        return (
          <BriefingAcquirersPointOfSale
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
            acquirers={acquirers}
            setAcquirers={setAcquirers}
            throughModal
            isCNPJMaintenance
          />
        );
      case CnpjPage.AcquirersVouchers:
        return (
          <BriefingAcquirersVoucher
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
            acquirers={acquirers}
            setAcquirers={setAcquirers}
            throughModal
            isVoucherNotAccepted={isVoucherNotAccepted}
            setIsVoucherNotAccepted={setIsVoucherNotAccepted}
            isCNPJMaintenance
          />
        );
      case CnpjPage.MerchantsRegister:
        return (
          <BriefingMerchantsRegister
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
            throughModal
            isCNPJMaintenance
            acquirerSelected={acquirerSelected}
            merchantHasPosted={merchantHasPosted}
            setMerchantHasPosted={setMerchantHasPosted}
          />
        );
      case CnpjPage.LettersAndOptins:
        return (
          <BriefingLettersAndOptins
            ref={childRef}
            isLocked={isLocked}
            setIsNextButtonLocked={setIsNextButtonLocked}
            throughModal
            isCNPJMaintenance
          />
        );
      case CnpjPage.Review:
        return <BriefingReview ref={childRef} />;
      default:
        return <Loadable loading />;
    }
  };

  const renderNextButton = () => {
    const onClick = () => {
      if (
        childRef &&
        childRef.current &&
        childRef.current.validateBeforeNextPage !== undefined &&
        currentPage !== 1 &&
        currentPage !== 2
      ) {
        if (currentPage === CnpjPage.PersonalData) {
          childRef.current.validateBeforeNextPage(afterValidatePersonalData);
        } else {
          childRef.current.validateBeforeNextPage(goToNextPage);
        }
      } else {
        goToNextPage();
      }
    };

    // Locks AcquirersPointsOfSale's modal button
    if (
      currentPage === CnpjPage.AcquirersPointOfSale &&
      acquirers?.some((a) => !a.checked)
    ) {
      setIsNextButtonLocked(true);
    }
    // Unlocks AcquirersPointsOfSale's modal button
    if (
      currentPage === CnpjPage.AcquirersPointOfSale &&
      acquirers?.some((a) => a.checked)
    ) {
      setIsNextButtonLocked(false);
    }

    // Locks AcquirersVouchers's modal button
    if (
      currentPage === CnpjPage.AcquirersVouchers &&
      acquirers?.some((a) => !a.checked) &&
      !isVoucherNotAccepted
    ) {
      setIsNextButtonLocked(true);
    }
    // Unlocks AcquirersVouchers's modal button
    if (
      currentPage === CnpjPage.AcquirersVouchers &&
      ((acquirers?.some((a) => a.checked) && !isVoucherNotAccepted) ||
        isVoucherNotAccepted)
    ) {
      setIsNextButtonLocked(false);
    }

    if (currentPage !== CnpjPage.Review) {
      return (
        <Button
          onClick={() => onClick()}
          disabled={
            isNextButtonLocked || (isNextButtonLocked && !isVoucherNotAccepted)
          }
          type="primary"
          style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
        >
          Próximo
        </Button>
      );
    }
    return '';
  };

  const renderFinishButton = () => {
    if (currentPage === CnpjPage.Review) {
      return (
        <Button
          onClick={onFinishBriefing}
          type="primary"
          style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
        >
          Finalizar
        </Button>
      );
    }
    return '';
  };

  const renderBackButton = () => {
    if (
      (currentPage !== CnpjPage.StoresRegister && screen === 0) ||
      (currentPage !== CnpjPage.AcquirersPointOfSale && screen === 1) ||
      (currentPage !== CnpjPage.MerchantsRegister && screen === 3) ||
      (currentPage !== CnpjPage.LettersAndOptins && screen === 4)
    ) {
      return (
        <Button
          onClick={() => goToPreviousPage()}
          style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
        >
          Voltar
        </Button>
      );
    }
    return '';
  };

  useEffect(() => {
    renderBackButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <StModal
      destroyOnClose
      centered
      bodyStyle={{ padding: '0 24px' }}
      open={visibility}
      style={{
        minWidth: '1200px',
        overflowWrap: 'break-word',
        height: 'auto',
      }}
      shouldCloseOnOverlayClick="true"
      onCancel={() => {
        setCurrentPage(screen);
        closeModal();
      }}
      footer={
        <>
          {renderFinishButton()}
          {renderNextButton()}
          {renderBackButton()}
          {savedDate && (
            <Typography.Text
              style={{
                float: 'right',
                marginRight: '20px',
                marginTop: '15px',
                color: '#ccc',
              }}
            >
              Salvo automaticamente em{' '}
              {dayjs(savedDate).format('DD/MM/YYYY [às] HH:mm')}
            </Typography.Text>
          )}
        </>
      }
    >
      <Row>
        <Col
          span={24}
          style={{
            marginRight: '0',
            marginLeft: '0',
            overflowY: 'auto',
            height: '735px',
          }}
        >
          {renderCurrentPage()}
        </Col>
      </Row>
    </StModal>
  );
};

export default CnpjModal;
