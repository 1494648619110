import axios from 'axios';
import errorHandler from 'helpers/errorHandler';
import { getSessionAccessToken } from 'helpers/sessionService';

export const getBaseUrl = () => {
  // Old endpoints in flask / fargate
  let baseUrl = process.env.REACT_APP_API_URL_DEV;
  const inProduction = process.env.REACT_APP_BUILD_ENV === 'production';
  if (inProduction === true) {
    baseUrl = process.env.REACT_APP_API_URL_PROD;
  }
  return baseUrl;
};

export const getBankingBaseUrl = () => {
  // Banking and Radar endpoints
  let baseUrl = process.env.REACT_APP_BANKING_API_URL_DEV;
  const inProduction = process.env.REACT_APP_BUILD_ENV === 'production';
  if (inProduction === true) {
    baseUrl = process.env.REACT_APP_BANKING_API_URL_PROD;
  }
  return baseUrl;
};

export const getLambdaBaseUrl = () => {
  const baseUrl = process.env.REACT_APP_LAMBDA_URL;
  return baseUrl;
};

export const getApiGatewayBaseUrl = () => {
  // New endpoints using API Gateway
  const baseUrl = process.env.REACT_APP_API_GATEWAY_URL_DEV;
  return baseUrl;
};

export const getAuthBaseUrl = () => {
  // Legacy code for old auth endpoints in flask / fargate
  // Now we use amplify directly to get the access token
  let baseUrl = process.env.REACT_APP_API_URL_AUTH_DEV;
  const inProduction = process.env.REACT_APP_BUILD_ENV === 'production';
  if (inProduction === true) {
    baseUrl = process.env.REACT_APP_API_URL_AUTH_PROD;
  }
  return baseUrl;
};

const intercepetorsResponseSuccess = (response) => response;

const interceptorsResponseError = (error) => {
  return Promise.reject(error);
};

const interceptorsResponseErrorWithoutAuth = (error) => {
  if (error?.response?.status) {
    if (error.response.status >= 500 && error.response.status <= 599) {
      if (error?.response?.data?.error?.message) {
        errorHandler(error.response.data.error.message);
      } else {
        errorHandler(
          'Ocorreu um erro na conexão com o servidor, por favor, atualize a página'
        );
      }
    } else {
      errorHandler(
        'Ocorreu um erro na conexão com o servidor, por favor, atualize a página'
      );
    }
  }
  return Promise.reject(error);
};

export const api = (url) => {
  let baseUrl = getBaseUrl();

  if (url) {
    baseUrl = url;
  }

  const token = getSessionAccessToken();
  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const apiGateway = (accessToken) => {
  const baseUrl = getApiGatewayBaseUrl();

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  if (!accessToken) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getSessionAccessToken()}`,
    };
  }

  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: headers,
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const bankingApi = (url) => {
  let baseUrl = getBankingBaseUrl();

  if (url) {
    baseUrl = url;
  }

  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: {
      Authorization: `Bearer ${getSessionAccessToken()}`,
    },
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const lambdaApi = (url) => {
  let baseUrl = getLambdaBaseUrl();

  if (url) {
    baseUrl = url;
  }

  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: {
      Authorization: `Bearer ${getSessionAccessToken()}`,
    },
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const openAcquirerApi = (token) => {
  const baseUrl = getLambdaBaseUrl();

  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const fileIdentificationApi = () => {
  const baseUrl = process.env.REACT_APP_FILE_IDENTIFICATION_API_URL;

  const token = getSessionAccessToken();
  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: {
      'Content-Type': 'application/json',
      // 'Is-Web': true,
      Authorization: `Bearer ${token}`,
    },
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const apiXLSXDownload = () => {
  const baseUrl = getBaseUrl();

  const token = getSessionAccessToken();
  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const apiCSVDownload = () => {
  const baseUrl = getBaseUrl();

  const token = getSessionAccessToken();
  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Disposition': 'attachment;',
      'Content-Type': 'text/csv',
    },
    responseType: 'arraybuffer',
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const apiUpload = () => {
  const baseUrl = getBaseUrl();

  const token = getSessionAccessToken();
  const network = axios.create({
    baseURL: baseUrl,
    timeout: 200000,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );

  return network;
};
export const apiUploadPresigned = () => {
  const network = axios.create({
    timeout: 200000,
  });

  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseErrorWithoutAuth(error)
  );

  return network;
};

export const apiDownload = () => {
  const baseUrl = getBaseUrl();

  const token = getSessionAccessToken();
  const network = axios.create({
    baseURL: baseUrl,
    timeout: 200000,
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization: `Bearer ${token}`,
    },
    responseType: 'arraybuffer',
  });

  return network;
};

export const s3File = (url) => {
  const network = axios.create({
    baseURL: url,
    timeout: 999990000,
    headers: {
      'Cache-Control': 'no-store no-cache must-revalidate',
      Pragma: 'no-cache',
    },
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};

export const registerApi = (token) => {
  const baseUrl = getLambdaBaseUrl();

  const headers = token && {
    Authorization: token,
  };

  const network = axios.create({
    baseURL: baseUrl,
    timeout: 999990000,
    headers: { ...headers },
  });
  network.interceptors.response.use(
    (response) => intercepetorsResponseSuccess(response),
    (error) => interceptorsResponseError(error)
  );
  return network;
};
