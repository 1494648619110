import { useCallback, useState } from 'react';
import {
  sendRegisterCode,
  validateRegisterCode,
} from 'services/register/registerCode';
import errorHandler from 'helpers/errorHandler';

export const useRegisterCode = () => {
  const [loading, setLoading] = useState(false);

  const onSendRegisterCode = useCallback(({ email, onFinish = () => {} }) => {
    setLoading(true);

    sendRegisterCode({ email })
      .then(() => onFinish())
      .catch(() =>
        errorHandler(
          'Ocorreu um erro ao enviar o código de verificação para o e-mail, por favor, tente novamente'
        )
      )
      .finally(() => setLoading(false));
  }, []);

  const onValidateRegisterCode = useCallback(
    ({ code, onFinish = () => {}, onError = () => {} }) => {
      setLoading(true);

      validateRegisterCode({ code })
        .then(() => onFinish())
        .catch(() => onError())
        .finally(() => setLoading(false));
    },
    []
  );

  return {
    loading,
    onSendRegisterCode,
    onValidateRegisterCode,
  };
};
