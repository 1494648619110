import styled, { keyframes } from 'styled-components';
import { Button, Divider, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { colors } from 'styles/colors';

const { Text } = Typography;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const FadeWrapper = styled.div`
  height: 100%;
  animation: ${({ step }) => (step ? fadeIn : fadeOut)} 0.5s ease forwards;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export const StPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props?.$breakpoint?.lg ? '100vw' : 'unset')};
  height: ${(props) => (props?.$breakpoint?.lg ? '100vh' : 'unset')};
`;

export const StContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${(props) => (props?.$breakpoint?.lg ? '60px' : '10px')};
  border-radius: 5px;
  overflow: hidden;
  animation: ${fadeIn} 0.5s ease forwards;
  box-shadow: ${(props) =>
    props?.$breakpoint?.lg ? 'rgba(0, 0, 0, 0.15) 0px 2px 8px' : 'unset'};
`;

export const StFormContainer = styled.div`
  position: relative;
  width: 438px;
  min-width: 438px;
  height: 100%;
  min-height: 864px;
  padding: 66px;
  background: ${colors.gray1};
`;

export const StBannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 438px;
  min-width: 438px;
  height: 100%;
  min-height: 864px;
  padding: 66px;
  background: ${colors.primary1};
`;

export const StBanner = styled.span`
  margin: 48px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: ${colors.primary6};
`;

export const StLogo = styled.img`
  margin-top: 40px;
  transform: translateX(-42px);
`;

export const StFormTitle = styled.h1`
  margin: 48px 0 16px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${colors.gray14};
`;

export const StButton = styled(Button)`
  width: 100%;
`;

export const StDivider = styled(Divider)`
  &&& {
    margin: 32px 0;
    font-size: 12px;
    color: ${colors.gray7};
  }
`;

export const StText = styled(Text)`
  color: ${colors.gray14};
  font-family: 'Roboto' sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;

export const StLink = styled(Link)`
  &&& {
    color: ${colors.primary6};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const StBackButton = styled(Link)`
  &&& {
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.gray7};
  }
`;
