import React, { useEffect, useState } from 'react';
import {
  StButton,
  StButtonContainer,
  StContent,
  StDescription,
  StDots,
  StImg,
  StModal,
  StPagination,
  StText,
  StTitle,
} from './styled';
import customHistory from 'helpers/history';
import { postUserOnboarding } from 'services/userOnboarding/postUserOnboarding';
import errorHandler from 'helpers/errorHandler';

export const UserOnboarding = ({
  flgTutorialCompleted = true,
  flgMerchantCreated = true,
  fetchPermissions,
  ...rest
}) => {
  const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const onboardingStep1 = `${assetsBaseURL}/img/onboardingStep1.png`;
  const onboardingStep2 = `${assetsBaseURL}/img/onboardingStep2.png`;
  const onboardingStep3 = `${assetsBaseURL}/img/onboardingStep3.png`;
  const onboardingStep4 = `${assetsBaseURL}/img/onboardingStep4.png`;
  const onboardingStep5 = `${assetsBaseURL}/img/onboardingStep5.png`;

  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5;
  const [userOnboardingLoading, setUserOnboardingLoading] = useState(false);

  const handleNextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleJumpTutorial = () => {
    setCurrentStep(5);
  };

  const goToCnpjMaintenance = async () => {
    if (flgTutorialCompleted) {
      customHistory.push('/manutencao-cnpj?aba=adquirentes&modal=adquirentes');
      return;
    }

    try {
      setUserOnboardingLoading(true);
      const response = await postUserOnboarding();

      const { code } = response;
      if (code === 201) {
        fetchPermissions();
        customHistory.push(
          '/manutencao-cnpj?aba=adquirentes&modal=adquirentes'
        );
      }
    } catch (error) {
      setUserOnboardingLoading(false);
      errorHandler('Ocorreu um erro:', error);
    }
  };

  const renderModalContent = (_currentStep) => {
    switch (_currentStep) {
      case 1:
        return (
          <>
            <StImg src={onboardingStep1} />

            <StTitle>Boas-vindas ao Concil Card!</StTitle>

            <StDescription>
              Conheça os benefícios que a Concil pode trazer para o seu negócio.
            </StDescription>
          </>
        );

      case 2:
        return (
          <>
            <StImg src={onboardingStep2} />

            <StTitle>Você no controle</StTitle>

            <StDescription>
              Tenha um resumo visual do desempenho da sua empresa.
            </StDescription>
          </>
        );

      case 3:
        return (
          <>
            <StImg src={onboardingStep3} />

            <StTitle>Detalhes que fazem a diferença</StTitle>

            <StDescription>
              Saiba seu fluxo de caixa e se planeje com antecedência.
            </StDescription>
          </>
        );

      case 4:
        return (
          <>
            <StImg src={onboardingStep4} />

            <StTitle>Clareza onde é mais necessário</StTitle>

            <StDescription>
              Consulte tudo que foi vendido em um único lugar, de forma simples
              e rápida.
            </StDescription>
          </>
        );

      case 5:
        return (
          <>
            <StImg src={onboardingStep5} />

            <StTitle>
              Vamos {!flgTutorialCompleted ? 'começar' : 'continuar'}?
            </StTitle>

            <StDescription>
              {!flgTutorialCompleted
                ? 'Cadastre uma maquininha para monitorar o desempenho da sua empresa.'
                : 'Conclua o cadastro e tenha visão de tudo que foi vendido.'}
            </StDescription>
          </>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (flgTutorialCompleted) {
      handleJumpTutorial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPrimaryButtonText = () => {
    if (flgTutorialCompleted && currentStep === 5) {
      return 'Continuar';
    }

    if (currentStep === 1) {
      return 'Iniciar';
    }

    if (currentStep === 5) {
      return 'Começar';
    }

    return 'Próximo';
  };

  return (
    <StModal
      centered
      open={!flgMerchantCreated}
      footer={null}
      closable={false}
      maskClosable={false}
      {...rest}
    >
      <StContent>
        {renderModalContent(currentStep)}

        <StPagination>
          <StDots>
            {[...Array(totalSteps)].map((_, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={currentStep === index + 1 ? 'active' : ''}
                // eslint-disable-next-line react/jsx-closing-tag-location
              ></span>
            ))}
          </StDots>
        </StPagination>

        <StButtonContainer>
          {currentStep > 1 && (
            <StButton type="secondary" onClick={handlePrevStep}>
              Voltar
            </StButton>
          )}

          <StButton
            type="primary"
            onClick={currentStep === 5 ? goToCnpjMaintenance : handleNextStep}
            loading={userOnboardingLoading}
          >
            {renderPrimaryButtonText()}
          </StButton>
        </StButtonContainer>

        {currentStep === 1 && (
          <StText onClick={handleJumpTutorial}>Pular etapas</StText>
        )}
      </StContent>
    </StModal>
  );
};
